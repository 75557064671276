export const defaultLayout = {
  currentBreakpoint: "lg",
  compactType: "vertical",
  mounted: false,
  toolbox: {
    lg: [
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 8,
        minH: 3,
        i: "2",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        x: 2,
        y: 0,
        i: "14",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 2,
        y: 0,
        i: "5",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        x: 1,
        y: 0,
        i: "8",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        i: "19",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        i: "19",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "9",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "10",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        minH: 3,
        i: "11",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 8,
        i: "19",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
  },
  layouts: {
    lg: [
      {
        w: 1,
        h: 10,
        x: 0,
        y: 0,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 3,
        h: 9,
        x: 1,
        y: 0,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    sm: [
      {
        w: 1,
        h: 6,
        x: 0,
        y: 0,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 5,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
    xs: [
      {
        w: 1,
        h: 6,
        x: 0,
        y: 44,
        i: "10",
        minH: 3,
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 6,
        x: 0,
        y: 18,
        i: "9",
        minH: 3,
        moved: false,
        static: false,
      },
    ],
  },
  currentDashboard: "Custom",
  currentDashboardTitle: "Custom Dashboard",
};
